import React from 'react';

import { makeStyles, Typography, Grid, Theme } from '@material-ui/core';
import clsx from 'clsx';
import GuaranteedIconComponent from 'components/icon-components/guaranteed-icon-component/GuaranteedIconComponent';
import Product from 'static/models/Product';
import { getFormattedPriceForLineItem } from 'toolboxes/reuseable-logic/products/productFunctions';

type StyleProps = {
    nameMaxWidthMedAndUp: number | string;
    nameMaxWidthSmall: number | string;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    lineItemInnerSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    lineItemLeft: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    lineItemRight: {
        textAlign: 'end',
        justifyContent: 'flex-end',
        paddingLeft: 5,
    },
    lineItemBorderLeft: {
        borderInlineStartWidth: 1,
        borderInlineStartColor: '#000000',
        borderInlineStartStyle: 'solid',
        paddingLeft: 5,
    },
    lineItemNoBorderPaddingLeft: {
        paddingLeft: 6,
    },
    lineHeight: {
        lineHeight: 1,
        minHeight: 21,
    },
    lineItemNameMaxWidth: {
        [theme.breakpoints.up('md')]: {
            maxWidth: ({ nameMaxWidthMedAndUp }) => nameMaxWidthMedAndUp,
        },
        [theme.breakpoints.only('sm')]: {
            maxWidth: ({ nameMaxWidthSmall }) => nameMaxWidthSmall,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 160,
        },
    },
    quantityWidth: {
        width: 25,
        textAlign: 'right',
        paddingRight: 5,
    },
    guaranteedIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    fontSizeSmall: {
        fontSize: '0.875rem',
    },
}));

const LineItemComponent: React.FunctionComponent<PropShape> = ({
    showQuantitySpace,
    product,
    orderSummaryPage,
    showGuaranteed,
}) => {
    const nameMaxWidthMedAndUp = orderSummaryPage ? 450 : 215;
    const nameMaxWidthSmall = orderSummaryPage ? 260 : 215;
    const classes = useStyles({
        nameMaxWidthMedAndUp,
        nameMaxWidthSmall,
    });

    return (
        <Grid container className={classes.lineItemInnerSection}>
            <Grid item className={classes.lineItemLeft}>
                {showQuantitySpace && (
                    <Grid item>
                        <div className={classes.quantityWidth}>
                            <Typography id="cart-line-item-quantity" variant="body2" className={classes.fontSizeSmall}>
                                {product.quantity > 1 ? product.quantity : ''}
                            </Typography>
                        </div>
                    </Grid>
                )}
                <Grid item className={clsx({ [classes.lineItemBorderLeft]: showQuantitySpace })}>
                    <Typography
                        variant="body2"
                        className={clsx(
                            classes.lineItemNameMaxWidth,
                            classes.fontSizeSmall,
                            classes.guaranteedIcon,
                            classes.lineHeight,
                        )}
                    >
                        {product.name}
                        <span id="cart-line-item-guaranteed-icon">
                            {product.guaranteed && showGuaranteed && <GuaranteedIconComponent size={21} />}
                        </span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item className={classes.lineItemRight}>
                <Typography id="cart-line-item-price" variant="body2" className={classes.fontSizeSmall}>
                    {getFormattedPriceForLineItem(product)}
                </Typography>
            </Grid>
        </Grid>
    );
};

interface PropShape {
    showQuantitySpace: boolean;
    product: Product;
    orderSummaryPage: boolean;
    showGuaranteed;
}

LineItemComponent.defaultProps = {
    showQuantitySpace: false,
    product: null,
    orderSummaryPage: false,
    showGuaranteed: false,
};

export default LineItemComponent;
